// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-car-ports-js": () => import("./../../../src/pages/car-ports.js" /* webpackChunkName: "component---src-pages-car-ports-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-golf-car-rentals-js": () => import("./../../../src/pages/golf-car-rentals.js" /* webpackChunkName: "component---src-pages-golf-car-rentals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-remote-services-js": () => import("./../../../src/pages/remote-services.js" /* webpackChunkName: "component---src-pages-remote-services-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-small-engine-repair-js": () => import("./../../../src/pages/small-engine-repair.js" /* webpackChunkName: "component---src-pages-small-engine-repair-js" */),
  "component---src-pages-welding-services-js": () => import("./../../../src/pages/welding-services.js" /* webpackChunkName: "component---src-pages-welding-services-js" */),
  "component---src-template-for-sale-js": () => import("./../../../src/template/for-sale.js" /* webpackChunkName: "component---src-template-for-sale-js" */),
  "component---src-template-product-js": () => import("./../../../src/template/product.js" /* webpackChunkName: "component---src-template-product-js" */)
}

